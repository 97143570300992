import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time-struct';
import { formatDate } from '@angular/common';

export function formDateCustom(
  date: NgbDateStruct,
  format: string = 'yyyy-MM-dd',
  locale: string = 'en-US'
) {
  if (!date) {
    return null;
  }
  return formatDate(
    date.year +
      '-' +
      formatCorrectly(date.month) +
      '-' +
      formatCorrectly(date.day),
    format,
    locale
  );
}

function formatCorrectly(input: number): string {
  return input < 10 ? `0${input}` : `${input}`;
}

export function formatDateTime(
  date: NgbDateStruct,
  time: NgbTimeStruct,
  format: string = 'yyyy-MM-ddTHH:mm:ss.sZ',
  locale: string = 'en-US'
) {
  return formatDate(
    date.year +
      '-' +
      formatCorrectly(date.month) +
      '-' +
      formatCorrectly(date.day) +
      ' ' +
      formatCorrectly(time.hour) +
      ':' +
      formatCorrectly(time.minute) +
      ':' +
      formatCorrectly(time.second),
    format,
    locale
  );
}
