<div class="login-dialog-container">
  <div class="login-header">
    <h3 class="h3 fw-bold dialog-title fw-bold mlk-text-dark-blue">
      Connexion
    </h3>
    <button
      (click)="dialogRef.close()"
      aria-label="Close"
      class="btn-close"
      type="button"></button>
  </div>

  <form [formGroup]="loginForm" class="mt-4">
    <div class="form-group mb-3">
      <label class="form-label fw-bold" for="email"
        >Email <span class="mlk-text-dark-grey fw-normal">- requis</span></label
      >
      <div class="input-group">
        <span class="input-group-text"><i class="bi bi-envelope-at"></i></span>
        <input
          aria-describedby="email-icon"
          aria-label="email"
          class="form-control"
          formControlName="email"
          id="email"
          placeholder="exemple@email.com"
          type="text" />
      </div>
      @if (email.invalid && (email.dirty || email.touched)) {
        <div class="text-danger mb-2">
          @if (email.getError('required')) {
            <span> L'email est requis </span>
          }
          @if (email.getError('email')) {
            <span> email invalide </span>
          }
        </div>
      }
    </div>
    <div class="form-group position-relative">
      <label class="form-label fw-bold" for="password"
        >Mot de passe
        <span class="mlk-text-dark-grey fw-normal">- requis</span></label
      >
      <span (click)="changePasswordVisibility()" class="hide-show-password-eye"
        ><i [class]="showPassword() ? 'bi bi-eye-slash' : 'bi bi-eye'"></i
      ></span>
      <div class="input-group">
        <span class="input-group-text"><i class="bi bi-lock"></i></span>
        <input
          [type]="showPassword() ? 'text' : 'password'"
          aria-describedby="basic-addon1"
          aria-label="password"
          class="form-control"
          formControlName="password"
          id="password"
          placeholder="Entrez votre mot de passe" />
      </div>
      @if (password.invalid && (password.dirty || password.touched)) {
        <div class="text-danger">
          @if (password.getError('required')) {
            <span> Le mot de passe est requis </span>
          }
        </div>
      }
      @if (formErrorFromServer(); as formErrorFromServerValue) {
        <div class="text-danger mt-2">
          {{ formErrorFromServerValue }}
        </div>
      }
    </div>
    <div class="form-group d-flex justify-content-between my-4">
      <div class="form-check form-switch">
        <input
          class="form-check-input"
          id="remember-me"
          name="remember-me"
          type="checkbox" />
        <label class="form-check-label" for="remember-me"
          >Se souvenir de moi</label
        >
      </div>
      <a
        href="javascript:void(0)"
        (click)="goTo('/reset-password')"
        class="fw-bold mlk-link mlk-text-dark-blue"
        >Mot de passe oublié</a
      >
    </div>
    <div class="form-group text-center mb-4">
      <mlk-button
        [variant]="'filled'"
        [type]="'primary'"
        [disabled]="loginForm.invalid"
        (click)="onSubmitForm()"
        [additionalClasses]="'w-100'">
        Se connecter
      </mlk-button>
    </div>
    <div class="form-group text-center">
      <p>
        Pas de compte ?
        <a
          href="javascript:void(0)"
          (click)="goTo('/register')"
          class="mlk-link fw-bold mlk-text-dark-blue"
          >Inscrivez-vous gratuitement</a
        >
      </p>
    </div>
  </form>
</div>
