import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export function validNgbDateStructValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as NgbDateStruct;
    if (!value) {
      return { invalidDate: '' }; // This is already managed by validators.required
    }

    const { year, month, day } = value;

    if (
      typeof year !== 'number' ||
      typeof month !== 'number' ||
      typeof day !== 'number'
    ) {
      return { invalidDate: 'la date est invalide' };
    }

    const date = new Date(year, month - 1, day);
    if (
      date.getFullYear() !== year ||
      date.getMonth() + 1 !== month ||
      date.getDate() !== day
    ) {
      return { invalidDate: 'la date est invalide' };
    }

    return null;
  };
}
