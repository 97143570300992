import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { Event } from 'gen';
import { CurrentUserLocalTimePipe } from '@common/pipes';

@Component({
  selector: 'mlk-event-information',
  standalone: true,
  imports: [DatePipe, TitleCasePipe, CurrentUserLocalTimePipe],
  templateUrl: './event-information.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    style: 'display: block',
  },
})
export class EventInformationComponent {
  @Input({ required: true }) public event!: Event;
}
