<div [formGroup]="relatedFormGroup()">
  <div [formArrayName]="relatedFormArrayName()">
    @for (
      partner of partnerArrayOfFormGroups();
      track partner;
      let index = $index
    ) {
      <div class="mlk-card m-0 mb-3" #singleFormGroup>
        <h5 class="h5 fw-bold mb-3">Conjoint(e)</h5>
        <div [formGroupName]="index">
          <!-- START PRENOM FIELD -->
          <div class="form-group mb-3">
            <label
              class="form-label mlk-text-black mlk-input-required fw-bold"
              for="firstname"
              >Prénom
              <span class="mlk-text-dark-grey fw-normal">- requis</span>
            </label>
            <div class="input-group">
              <span class="input-group-text"
                ><em class="bi bi-person"></em
              ></span>
              <input
                aria-describedby="user-name-icon2"
                aria-label="firstname"
                class="form-control"
                formControlName="first_name"
                id="firstname"
                type="text" />
            </div>
            @if (
              firstNameOf(partner)?.invalid &&
              (firstNameOf(partner)?.dirty || firstNameOf(partner)?.touched)
            ) {
              <div class="text-danger">
                @if (
                  firstNameOf(partner)?.hasError('required') ||
                  firstNameOf(partner)?.hasError('minLength')
                ) {
                  <span> Veuillez renseigner le prénom </span>
                }
                @if (firstNameOf(partner)?.hasError('maxLength')) {
                  <span> Le prénom ne peut pas excéder 50 caractères </span>
                }
              </div>
            }
            @if (firstNameOf(partner)?.hasError('server')) {
              <div class="text-danger">
                {{ firstNameOf(partner)?.errors?.['server'] }}
              </div>
            }
          </div>
          <!-- END PRENOM FIELD-->

          <!-- START NOM FIELD -->
          <div class="form-group mb-3">
            <label
              class="form-label mlk-text-black mlk-input-required fw-bold"
              for="lastname"
              >Nom
              <span class="mlk-text-dark-grey fw-normal">- requis</span>
            </label>
            <div class="input-group">
              <span class="input-group-text"
                ><em class="bi bi-person"></em
              ></span>
              <input
                aria-describedby="user-name-icon1"
                aria-label="lastname"
                class="form-control"
                formControlName="last_name"
                id="lastname"
                type="text" />
            </div>
            @if (lastNameOf(partner)?.hasError('server')) {
              <div class="text-danger">
                {{ lastNameOf(partner)?.errors?.['server'] }}
              </div>
            }
            @if (
              lastNameOf(partner)?.invalid &&
              (lastNameOf(partner)?.dirty || lastNameOf(partner)?.touched)
            ) {
              <div class="text-danger">
                @if (
                  lastNameOf(partner)?.hasError('required') ||
                  lastNameOf(partner)?.hasError('minLength')
                ) {
                  <span> Vous devez renseigner le nom </span>
                }
                @if (lastNameOf(partner)?.hasError('maxLength')) {
                  <span> Le nom ne peut pas excéder 50 caractères </span>
                }
              </div>
            }
          </div>
          <!-- END NOM FIELD -->

          <!-- START GENDER FIELD -->
          <div class="form-group mb-3">
            <label
              class="form-label mlk-text-black mlk-input-required fw-bold"
              for="gender"
              >Genre
              <span class="mlk-text-dark-grey fw-normal">- requis</span>
            </label>
            <div class="input-group">
              <span class="input-group-text"
                ><em class="bi bi-person"></em
              ></span>
              <select
                aria-label="gender"
                class="form-select"
                formControlName="gender"
                id="gender">
                <option [selected]="true" value="">Choisir</option>
                <option value="male">Homme</option>
                <option value="female">Femme</option>
              </select>
            </div>
            @if (
              genderOf(partner)?.invalid &&
              (genderOf(partner)?.dirty || genderOf(partner)?.touched)
            ) {
              <div class="text-danger">
                @if (genderOf(partner)?.hasError('required')) {
                  <span> Veuillez renseigner le genre </span>
                }
              </div>
            }
            @if (genderOf(partner)?.hasError('server')) {
              <div class="text-danger">
                {{ genderOf(partner)?.errors?.['server'] }}
              </div>
            }
          </div>
          <!-- END GENDER FIELD -->

          <!-- START EMAIL FIELD -->
          <div class="form-group mb-3">
            <label
              class="form-label mlk-text-black mlk-input-required fw-bold"
              for="email"
              >Email
            </label>
            <div class="input-group">
              <span class="input-group-text text-bold"
                ><em class="bi bi-envelope"></em
              ></span>
              <input
                aria-describedby="email-icon"
                aria-label="email"
                class="form-control"
                formControlName="email"
                id="email"
                type="email" />
            </div>
            @if (
              emailOf(partner)?.invalid &&
              (emailOf(partner)?.dirty || emailOf(partner)?.touched)
            ) {
              <div class="text-danger">
                @if (
                  emailOf(partner)?.hasError('required') ||
                  emailOf(partner)?.hasError('minLength')
                ) {
                  <span> Veuillez renseigner l'email </span>
                }
                @if (emailOf(partner)?.hasError('maxLength')) {
                  <span> l'email ne peut pas excéder 50 caractères </span>
                }
                @if (emailOf(partner)?.hasError('email')) {
                  <span> l'adresse mail est invalide </span>
                }
              </div>
            }
            @if (emailOf(partner)?.hasError('server')) {
              <div class="text-danger">
                {{ emailOf(partner)?.errors?.['server'] }}
              </div>
            }
          </div>
          <!-- END EMAIL FIELD -->

          <!-- START TELEPHONE FIELD -->
          <div class="form-group mb-3">
            <label
              class="form-label mlk-text-black mlk-input-required fw-bold"
              for="telephone"
              >Téléphone
            </label>
            <div class="input-group">
              <!--            <input type="telephone" id="telephone" formControlName="phone_number" class="form-control"-->
              <!--                   aria-label="telephone" aria-describedby="email-icon">-->
              <ngx-intl-tel-input
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="false"
                [maxLength]="15"
                [phoneValidation]="true"
                [preferredCountries]="[countryISO()]"
                [searchCountryField]="searchCountryField()"
                [searchCountryFlag]="true"
                [selectFirstCountry]="false"
                [selectedCountryISO]="countryISO()"
                [separateDialCode]="true"
                formControlName="phone_number"
                id="telephone"></ngx-intl-tel-input>
            </div>
            @if (
              phoneNumberOf(partner)?.invalid && phoneNumberOf(partner)?.touched
            ) {
              <div class="text-danger">Le numéro de téléphone est requis.</div>
            }
            @if (phoneNumberOf(partner)?.hasError('server')) {
              <div class="text-danger">
                {{ phoneNumberOf(partner)?.errors?.['server'] }}
              </div>
            }
          </div>
          <!-- END THELEPHONE FIELD -->

          <!--START SHOULD REMEMBER FIELD-->
          <div class="form-group mb-3">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                formControlName="is_reusable"
                id="is_reusable"
                name="is_reusable"
                type="checkbox" />
              <label class="form-check-label" for="is_reusable"
                >Enregistrer pour la prochaine fois
              </label>
            </div>
          </div>
          <!--END SHOULD REMEMBER FIELD-->

          <mlk-button
            additionalClasses="mt-3 shadow"
            variant="filled"
            type="primary"
            ngbTooltip="Retirer"
            placement="right"
            (click)="removePartnerEvent.emit(index)">
            <i class="bi bi-trash3"></i>
          </mlk-button>
        </div>
      </div>
    }
  </div>
</div>
