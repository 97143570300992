<ng-template #groupMember let-modal> Lorem</ng-template>
<div class="mb-4">
  <div class="d-flex justify-content-between">
    @if (eventAttendee().status; as eventStatus) {
      <div
        [ngClass]="{
          'mlk-bg-light-blue-gradient mlk-text-dark-blue':
            eventStatus === 'confirmed',
          'mlk-bg-dark-blue mlk-text-white': eventStatus === 'pending',
          'mlk-bg-light-grey mlk-text-dark-grey': eventStatus === 'refused'
        }"
        class="mlk-badge fw-bold p-2">
        {{ eventStatus | statusAttendee }}
      </div>
    }
    <!--<div class="mlk-text-dark-blue fw-bold mlk-link">Voir son profil</div>-->
  </div>
  <h3 class="h3 fw-bold mt-4">
    {{ eventAttendee().user.first_name }} {{ eventAttendee().user.last_name }}
  </h3>
  @if (eventAttendee().user.phone_number) {
    <div class="mt-3">
      <span class="mlk-text-dark-blue"
        ><i class="bi bi-telephone fs-4"></i
      ></span>
      &nbsp;
      {{ eventAttendee().user.phone_number }}
    </div>
  }
</div>

@if (eventAttendee().partner; as attendeePartner) {
  <div class="mb-4">
    <h4 class="mlk-text-dark-blue h4 mb-3">
      @if (attendeePartner.gender === 'female') {
        Conjointe
      } @else {
        Conjoint
      }
    </h4>
    <h5 class="h5">
      {{ attendeePartner.first_name }} {{ attendeePartner.last_name }}
    </h5>
    @if (attendeePartner.phone_number) {
      <div class="mt-3">
        <span class="mlk-text-dark-blue"
          ><i class="bi bi-telephone fs-4"></i
        ></span>
        {{ attendeePartner.phone_number }}
      </div>
    }
  </div>
}

@if (eventAttendee().children.length) {
  <div class="mb-4">
    <h4 class="mlk-text-dark-blue h4 mb-3">Enfant(s)</h4>
    @for (
      attendeeChildren of eventAttendee().children;
      track attendeeChildren.id
    ) {
      <h5 class="h5 mb-2">
        {{ attendeeChildren.first_name }}
        {{ attendeeChildren.last_name }}
      </h5>
    }
  </div>
}

@if (eventAttendee().guests.length) {
  <div>
    <h4 class="mlk-text-dark-blue h4 mb-3">Invité(es)</h4>
    @for (attendeeGuest of eventAttendee().guests; track attendeeGuest.id) {
      <div class="mb-2">
        <h5 class="h5">
          {{ attendeeGuest.first_name }} {{ attendeeGuest.last_name }}
        </h5>
        @if (attendeeGuest.phone_number) {
          <div class="mt-3">
            <span class="mlk-text-dark-blue"
              ><i class="bi bi-telephone fs-4"></i
            ></span>
            {{ attendeeGuest.phone_number }}
          </div>
        }
      </div>
    }
  </div>
}
