import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
  output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { ChangeData, CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { FormErrors, catchFormError } from '@common/rxjs';

import { GenderEnum } from '../../../../../gen';

@Component({
  selector: 'app-attendee-group-member',
  standalone: true,
  imports: [ReactiveFormsModule, NgxIntlTelInputModule],
  templateUrl: './attendee-group-member.component.html',
  styleUrl: './attendee-group-member.component.scss',
})
export class AttendeeGroupMemberComponent {
  private readonly formBuilder = inject(FormBuilder);

  readonly addedMember = output<any>();

  public updateProfileForm = this.formBuilder.group({
    family_link: new FormControl('', {
      nonNullable: true,
      validators: Validators.required,
    }),
    first_name: new FormControl('', {
      nonNullable: true,
      validators: Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]),
    }),
    last_name: new FormControl('', {
      nonNullable: true,
      validators: Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]),
    }),
    gender: new FormControl<GenderEnum | ''>('', {
      nonNullable: true,
      validators: Validators.required,
    }),
    phone_number: new FormControl<ChangeData | undefined>(undefined, {
      nonNullable: true,
      validators: Validators.required,
    }),
    email: new FormControl('', {
      nonNullable: true,
      validators: Validators.compose([
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(254),
        Validators.email,
      ]),
      updateOn: 'blur',
    }),
  });

  readonly formErrorFromServer = signal<FormErrors | null>(null);
  private catchErrorFormServer = catchFormError(this.formErrorFromServer);
  readonly familyLink = this.updateProfileForm.controls.family_link;
  readonly phoneNumber = this.updateProfileForm.controls.phone_number;
  readonly firstName = this.updateProfileForm.controls.first_name;
  readonly lastName = this.updateProfileForm.controls.last_name;
  readonly gender = this.updateProfileForm.controls.gender;
  readonly email = this.updateProfileForm.controls.email;

  readonly searchCountryField = [
    SearchCountryField.Iso2,
    SearchCountryField.Name,
  ];
  readonly countryISOFr = CountryISO.France;

  onSubmit = () => {
    this.formErrorFromServer.set(null);
    if (this.updateProfileForm.valid) {
      alert(
        'familyLink: ' +
          this.familyLink.value +
          '\n' +
          'First_name: ' +
          this.firstName.value +
          'Last_name: ' +
          this.lastName.value
      );

      this.addedMember.emit({
        familyLink: this.familyLink.value,
        First_name: this.firstName.value,
        Last_name: this.lastName.value,
      });
    }
  };
}
