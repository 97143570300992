@if (event) {
  <div>
    <ng-content select="[mlk-event-status]"></ng-content>
    <div class="mb-2">
      <h4 class="h4 fw-bold">{{ event.name }}</h4>
      <p>{{ event.description }}</p>
    </div>
    <div class="d-flex align-items-center m-2">
      <span class="mlk-text-dark-blue"
        ><i class="bi bi-calendar fs-4"></i
      ></span>
      <div class="d-flex gap-0 flex-column">
        <small class="fs-6 fw-bold">{{
          event.starts_on | date: 'EEEE d MMMM y' | titlecase
        }}</small>
        <small
          >de {{ event.starts_on | currentUserLocalTime: 'HH:mm' }} à
          {{ event.ends_on | currentUserLocalTime: 'HH:mm' }}</small
        >
      </div>
    </div>
    <ng-content select="[mlk-event-additional-info]"></ng-content>
    <ng-content select="[mlk-event-actions]"></ng-content>
    <!-- END EVENT CARD -->
  </div>
}
