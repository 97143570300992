import {
  ChangeDetectionStrategy,
  Component,
  Input,
  computed,
  signal,
  input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  Event,
  EventCreationRequest,
  EventRequest,
  EventsService,
  EmbassiesService,
} from '../../../../../gen';
import { Router } from '@angular/router';
import { catchError, EMPTY, filter, map } from 'rxjs';
import { ToastService } from '../../services/toast/toast.service';
import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
  NgbDatepickerModule,
  NgbModal,
  NgbTimepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time-struct';
import { FormErrors, catchFormError } from '@common/rxjs';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { checkHours } from '@shared/validators';
import { ModalWithServiceComponent } from '../modal-with-service/modal-with-service.component';

@Component({
  selector: 'mlk-new-event',
  templateUrl: './new-event.component.html',
  styleUrls: ['./new-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, NgbDatepickerModule, NgbTimepickerModule],
})
export class NewEventComponent implements OnInit {
  public embassyId = input.required<string>();

  @Input({ required: false })
  public set event(currentEvent: Event | undefined) {
    this.currentEvent.set(currentEvent);
  }

  that = this;

  readonly embassyOpenDays = signal<string[]>([]);

  private currentEvent = signal<Event | undefined>(undefined);

  private currentEvent$ = toObservable(this.currentEvent).pipe(
    filter((ev): ev is Event => !!ev)
  );

  ngOnInit(): void {
    this.getEmbassyOpenDays(this.embassyId()).subscribe(openDays => {
      this.embassyOpenDays.set(openDays);
    });
  }

  public getEmbassyOpenDays(embassyId: string) {
    return this.embassiesService.embassiesRetrieve({ id: embassyId }).pipe(
      map(embassy => {
        return embassy.open_on_days;
      })
    );
  }

  public minSelectableDate: NgbDate = this.calendar.getNext(
    this.calendar.getToday(),
    'd'
  );

  public eventId = computed(() => {
    const currentEvent = this.currentEvent();

    if (currentEvent && currentEvent.id) {
      return currentEvent.id;
    }
    return null;
  });

  public text = computed(() =>
    this.eventId() ? `Modifier l'événement` : 'Créer un événement'
  );

  public eventForm = this.formBuilder.group(
    {
      name: new FormControl('', {
        nonNullable: true,
        validators: Validators.required,
      }),
      description: new FormControl('', { nonNullable: true }),
      date: new FormControl<NgbDateStruct | undefined>(undefined, {
        nonNullable: true,
        validators: Validators.required,
      }),
      from: new FormControl<NgbTimeStruct | null>(null, {
        validators: Validators.required,
      }),
      to: new FormControl<NgbTimeStruct | null>(null, {
        validators: Validators.required,
      }),
    },
    { validators: checkHours('from', 'to') }
  );

  private formErrorFromServer = signal<FormErrors>({});

  private catchErrorFormServer = catchFormError(this.formErrorFromServer);

  public nameError = computed(() => this.formErrorFromServer()['name']);
  public descriptionError = computed(
    () => this.formErrorFromServer()['description']
  );
  public startOnError = computed(() => this.formErrorFromServer()['starts_on']);
  public endOnError = computed(() => this.formErrorFromServer()['ends_on']);

  constructor(
    private ngbModal: NgbModal,
    private formBuilder: FormBuilder,
    private router: Router,
    private eventService: EventsService,
    private toastService: ToastService,
    private calendar: NgbCalendar,
    private embassiesService: EmbassiesService
  ) {
    this.currentEvent$
      .pipe(
        map(res => {
          const event = res;
          const endTime = new Date(event.ends_on);
          const startDate = new Date(event.starts_on);
          const to = {
            hour: endTime.getHours(),
            minute: endTime.getMinutes(),
            second: endTime.getSeconds(),
          };
          const from = {
            hour: startDate.getHours(),
            minute: startDate.getMinutes(),
            second: startDate.getSeconds(),
          };
          const date = {
            day: startDate.getDate(),
            month: startDate.getMonth() + 1,
            year: startDate.getFullYear(),
          };

          const { name, description } = event;
          this.eventForm.patchValue({
            name,
            description: description ?? '',
            from,
            to,
            date,
          });
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  get name() {
    return this.eventForm.controls.name;
  }

  get description() {
    return this.eventForm.controls.description;
  }

  get date() {
    return this.eventForm.controls.date;
  }

  get fromHour() {
    return this.eventForm.controls.from;
  }

  get toHour() {
    return this.eventForm.controls.to;
  }

  isDisabled = (date: NgbDateStruct): boolean => {
    const daysOfWeek = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ];
    const myDay = new Date(date.year, date.month - 1, date.day).getDay();
    return (
      this.embassyOpenDays().find(day => daysOfWeek.indexOf(day) === myDay) ===
      undefined
    );
  };

  open(content: unknown): void {
    this.ngbModal
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
      })
      .result.then(() => this.onSubmitForm());
  }

  formatCorrectly(input: number): string {
    return input < 10 ? `0${input}` : `${input}`;
  }

  onSubmitForm(): void {
    // Build timestamp
    const dateInput = this.date.value as NgbDateStruct;
    const fromInput = this.fromHour.value as NgbTimeStruct;
    const toInput = this.toHour.value as NgbTimeStruct;

    // From hour must be < To to Hour
    const starts_on = formatDate(
      dateInput.year +
        '-' +
        this.formatCorrectly(dateInput.month) +
        '-' +
        this.formatCorrectly(dateInput.day) +
        ' ' +
        this.formatCorrectly(fromInput.hour) +
        ':' +
        this.formatCorrectly(fromInput.minute) +
        ':' +
        this.formatCorrectly(fromInput.second),
      'yyyy-MM-ddTHH:mm:ss.sZ',
      'en-US'
    );

    const ends_on = formatDate(
      dateInput.year +
        '-' +
        this.formatCorrectly(dateInput.month) +
        '-' +
        this.formatCorrectly(dateInput.day) +
        ' ' +
        this.formatCorrectly(toInput.hour) +
        ':' +
        this.formatCorrectly(toInput.minute) +
        ':' +
        this.formatCorrectly(toInput.second),
      'yyyy-MM-ddTHH:mm:ss.sZ',
      'en-US'
    );

    const id = this.eventId();

    const eventRequest = {
      name: this.name.value,
      description: this.description.value,
      starts_on,
      ends_on,
    };

    if (id) {
      this.updateTheEvent(id, eventRequest);
    } else {
      const eventCreation: EventCreationRequest = {
        ...eventRequest,
        embassy_id: this.embassyId(),
      };
      this.createNewEvent(eventCreation);
    }
  }

  updateTheEvent(eventId: string, eventRequest: EventRequest): void {
    this.eventService
      .eventsUpdate({ id: eventId, eventRequest })
      .pipe(
        map(event => {
          if (event) {
            this.modalConfirm().then(value => {
              if (value) {
                location.reload();
              }
            });
          } else {
            this.toastService.showDanger(
              "Votre événement n'a pas pu être enregistré. Veuillez réessayer plus tard."
            );
          }
        }),
        this.catchErrorFormServer
      )
      .subscribe();
  }

  createNewEvent(eventCreationRequest: EventCreationRequest): void {
    this.eventService
      .eventsCreate({ eventCreationRequest })
      .pipe(
        map(eventCreation => {
          if (eventCreation.id !== undefined) {
            this.eventForm.reset();
            this.modalConfirm().then(value => {
              if (value) {
                this.router.navigate([
                  '/mlk/event/amb/',
                  eventCreation.id,
                  eventCreation.embassy_id,
                ]);
              }
            });
          }
        }),
        catchError(() => {
          this.toastService.showDanger(
            "Votre événement n'a pas pu être enregistré. Veuillez réessayer plus tard."
          );
          return EMPTY;
        })
      )
      .subscribe();
  }

  public modalConfirm(): Promise<unknown> {
    const ngbModalRef = this.ngbModal.open(ModalWithServiceComponent, {
      centered: true,
    });
    ngbModalRef.componentInstance.title = `Votre événement a bien été enregistré.`;
    ngbModalRef.componentInstance.content = `Votre événement a bien été enregistré.`;
    ngbModalRef.componentInstance.buttonOk = {
      id: 'accepter',
      label: "Voir l'événement",
      cssClasses: 'mlk-btn-blue-filled',
      link: ``,
    };
    ngbModalRef.componentInstance.icon = 'accepted';

    return ngbModalRef.result;
  }
}
