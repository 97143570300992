<div class="p-2 align-items-center">
  <div class="d-flex flex-row justify-content-end w-100">
    <button
      type="button"
      class="btn-close"
      (click)="dismiss()"
      aria-label="Close"></button>
  </div>
  <h4 class="mlk-text-black fw-bold text-center">{{ title }}</h4>
  <div
    class="modal-body py-3 pd-lg-0 d-flex flex-column gap-4 align-items-center">
    <div
      class="d-flex flex-column flex-md-row gap-2 gal-md-3 justify-content-center align-items-center">
      <p>{{ content }}</p>
      <ng-template #informationContent>
        <h6 class="h6">{{ informationIconTitle }}</h6>
        <p>{{ informationIconContent }}</p>
      </ng-template>
      @if (informationIconContent) {
        <i
          [ngbTooltip]="informationContent"
          class="bi bi-question-circle fs-4 mlk-text-dark-blue"
          placement="bottom"
          tooltipClass="mlk-tooltip"
          triggers="click"></i>
      }
    </div>
    @if (icon) {
      <img class="mlk-modal-image" src="/assets/images/{{ icon }}.webp" />
    }
  </div>
  <div
    class="d-flex flex-column mt-3 w-100 flex-md-row gap-3 justify-content-md-center">
    @if (buttonCancel) {
      <a
        id="{{ buttonCancel.id }}"
        class="btn {{ buttonCancel.cssClasses }}"
        (click)="decline()">
        {{ buttonCancel.label }}
      </a>
    }
    @if (buttonOk) {
      <a
        id="{{ buttonOk.id }}"
        class="btn {{ buttonOk.cssClasses }}"
        (click)="accept()">
        {{ buttonOk.label }}
      </a>
    }
  </div>
</div>
