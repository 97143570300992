import {
  Component,
  computed,
  inject,
  input,
  Input,
  OnInit,
  output,
  signal,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  ChangeData,
  CountryISO,
  NgxIntlTelInputModule,
  SearchCountryField,
} from 'ngx-intl-tel-input';
import { complexFormErrors } from '@common/rxjs';
import { NgTemplateOutlet } from '@angular/common';
import { AttendeeGroupItemComponent } from '@shared/components';
import { GuestAttendeeFormComponent } from '@shared/components/form/guest-attendee-form/guest-attendee-form.component';
import { ChildAttendeeFormComponent } from '@shared/components/form/child-attendee-form/child-attendee-form.component';
import { PartnerAttendeeFormComponent } from '@shared/components/form/partner-attendee-form/partner-attendee-form.component';
import { formDateCustom } from '@shared/helpers/date/date.helper';
import { EventAttendeesFormData } from '@shared/interfaces/event-attendee-group-form.interface';
import { MlkButtonComponent } from '@shared/ui';
import { RouterLink } from '@angular/router';
import { GenderEnum } from '../../../../../gen';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { validNgbDateStructValidator } from '@shared/validators/date.validators';

@Component({
  selector: 'app-attendees-group',
  standalone: true,
  templateUrl: './attendees-group.component.html',
  styleUrl: './attendees-group.component.scss',
  imports: [
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    NgTemplateOutlet,
    AttendeeGroupItemComponent,
    GuestAttendeeFormComponent,
    ChildAttendeeFormComponent,
    PartnerAttendeeFormComponent,
    MlkButtonComponent,
    RouterLink,
  ],
})
export class AttendeesGroupComponent implements OnInit {
  @Input({ required: true }) public userId!: string;
  partnerIsAlreadySetThroughCheckBox = input.required<boolean>();
  currentNumberOfAttendees = input.required<number>();
  nbrOfCheckedChildren = input.required<number>();
  numberOfPartner = signal<number>(0);
  canAddPartnerThroughForm = computed(
    () =>
      this.numberOfPartner() == 0 && !this.partnerIsAlreadySetThroughCheckBox()
  );
  updateNumberOfAttendeesEvent = output<number>();
  public submitAttendeFormGroup = output<EventAttendeesFormData>();
  readonly formErrorFromServer = input.required<complexFormErrors>({});

  public readonly countryISO = CountryISO.France;

  public readonly searchCountryField = [
    SearchCountryField.Iso2,
    SearchCountryField.Name,
  ];

  @ViewChild(ChildAttendeeFormComponent)
  childrenFormComponent!: ChildAttendeeFormComponent;

  @ViewChild(PartnerAttendeeFormComponent)
  partnerAttendeeFormComponent!: PartnerAttendeeFormComponent;

  @ViewChild(GuestAttendeeFormComponent)
  guestAttendeeFormComponent!: GuestAttendeeFormComponent;

  private nbrAdded = 1;
  private readonly formBuilder = inject(FormBuilder);
  eventAttendeesForm = this.formBuilder.group({
    children: this.formBuilder.array([]),
    guests: this.formBuilder.array([]),
    partner: this.formBuilder.array([]),
  });

  get guests(): FormArray {
    return this.eventAttendeesForm.get('guests') as FormArray;
  }

  get children(): FormArray {
    return this.eventAttendeesForm.get('children') as FormArray;
  }

  get partner(): FormArray {
    return this.eventAttendeesForm.get('partner') as FormArray;
  }

  ngOnInit() {
    this.eventAttendeesForm.valueChanges.subscribe(() =>
      this.notifyNumberOfAttende()
    );
  }

  notifyNumberOfAttende() {
    this.numberOfPartner.set(this.partner.length);
    const total =
      this.children.length + this.guests.length + this.partner.length;
    this.updateNumberOfAttendeesEvent.emit(total);
  }

  addGuest() {
    this.guests.push(
      this.formBuilder.group({
        first_name: this.getFormControls().first_name,
        last_name: this.getFormControls().last_name,
        gender: this.getFormControls().gender,
        email: this.getFormControls().email,
        phone_number: this.getFormControls().phone_number,
        age_range: this.getFormControls().age_range,
      })
    );

    setTimeout(
      () => this.scrollToLastAddedFormIn(this.guestAttendeeFormComponent),
      0
    );
  }

  removeGuest(guestIndex: number) {
    this.guests.removeAt(guestIndex);
  }

  addChild(): void {
    this.children.push(
      this.formBuilder.group({
        first_name: this.getFormControls().first_name,
        last_name: this.getFormControls().last_name,
        gender: this.getFormControls().gender,
        is_reusable: this.getFormControls().is_reusable,
        date_of_birth: this.getFormControls().date_of_birth,
      })
    );

    setTimeout(
      () => this.scrollToLastAddedFormIn(this.childrenFormComponent),
      0
    );
  }

  removeChild(childIndex: number): void {
    this.children.removeAt(childIndex);
  }

  addPartner(): void {
    if (this.partner.length === 1) return;

    this.partner.push(
      this.formBuilder.group({
        first_name: this.getFormControls().first_name,
        last_name: this.getFormControls().last_name,
        gender: this.getFormControls().gender,
        email: new FormControl(null, {
          validators: Validators.compose([
            Validators.minLength(5),
            Validators.maxLength(254),
            Validators.email,
          ]),
          updateOn: 'blur',
        }),

        phone_number: new FormControl<ChangeData | null>(null),
        is_reusable: this.getFormControls().is_reusable,
      })
    );

    setTimeout(
      () => this.scrollToLastAddedFormIn(this.partnerAttendeeFormComponent),
      0
    );
  }

  removePartner(partnerIndex: number): void {
    this.partner.removeAt(partnerIndex);
  }

  onSubmit(): void {
    if (this.eventAttendeesForm.invalid) {
      this.eventAttendeesForm.markAllAsTouched();
      return;
    }
    this.children.controls.forEach((childFormGroup: AbstractControl) => {
      const dateOfBirthFormControl = childFormGroup.get('date_of_birth');
      dateOfBirthFormControl?.setValue(
        formDateCustom(dateOfBirthFormControl?.value)
      );
    });

    this.partner.controls.forEach((partnerFormGroup: AbstractControl) => {
      const phoneFormControl = partnerFormGroup.get('phone_number');
      phoneFormControl?.setValue(phoneFormControl?.value?.internationalNumber);
    });

    this.guests.controls.forEach((guestFormGroup: AbstractControl) => {
      const phoneFormControl = guestFormGroup.get('phone_number');
      phoneFormControl?.setValue(phoneFormControl?.value?.internationalNumber);
    });

    this.submitAttendeFormGroup.emit({
      children: this.children.value,
      guests: this.guests.value,
      partner: this.partner.controls[0]?.value, // there is only on partner
    });
  }

  private getFormControls() {
    return {
      first_name: new FormControl('', {
        nonNullable: true,
        validators: Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ]),
      }),

      last_name: new FormControl('', {
        nonNullable: true,
        validators: Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ]),
      }),

      gender: new FormControl<GenderEnum | ''>('', {
        nonNullable: true,
        validators: Validators.required,
      }),

      email: new FormControl('', {
        nonNullable: true,
        validators: Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(254),
          Validators.email,
        ]),
        updateOn: 'blur',
      }),

      phone_number: new FormControl<ChangeData | undefined>(undefined, {
        nonNullable: true,
        validators: Validators.required,
      }),

      age_range: new FormControl('', {
        nonNullable: true,
        validators: Validators.required,
      }),

      is_reusable: new FormControl(false, {
        nonNullable: true,
        validators: Validators.required,
      }),

      date_of_birth: new FormControl<NgbDateStruct | null>(null, {
        nonNullable: true,
        validators: Validators.compose([
          Validators.required,
          validNgbDateStructValidator(),
        ]),
      }),
    };
  }

  private scrollToLastAddedFormIn(
    component:
      | ChildAttendeeFormComponent
      | GuestAttendeeFormComponent
      | PartnerAttendeeFormComponent
  ) {
    if (component?.allFormGroups && component?.allFormGroups.length > 0) {
      const newAttendeeForm = component.allFormGroups?.last?.nativeElement;
      newAttendeeForm.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
