import {
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  Injector,
  input,
  OnInit,
  output,
  QueryList,
  runInInjectionContext,
  Signal,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { complexFormErrors, semiComplexFormErrors } from '@common/rxjs';
import { NgbInputDatepicker, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { MlkButtonComponent } from '@shared/ui';
import { RouterLink } from '@angular/router';
import { BaseFormClass } from '@shared/components/form/base-form-class';

@Component({
  selector: 'app-child-attendee-form',
  standalone: true,
  imports: [
    FormsModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    NgbInputDatepicker,
    MlkButtonComponent,
    RouterLink,
    NgbTooltip,
  ],
  templateUrl: './child-attendee-form.component.html',
  styleUrl: './child-attendee-form.component.scss',
})
export class ChildAttendeeFormComponent
  extends BaseFormClass
  implements OnInit
{
  formErrorFromServer = input.required<complexFormErrors>({});
  nbrOfCheckedChildren = input.required<number>();
  childrenFormServerError: Signal<semiComplexFormErrors[]> = computed(() =>
    this.getOnlyErrorsRelatedToChildrenFormGroups()
  );
  childrenArrayOfFormGroups = input.required<AbstractControl[]>();
  relatedFormArrayName = input.required<string>();
  relatedFormGroup = input.required<FormGroup>();
  removeChildEvent = output<number>();
  injector = inject(Injector);

  @ViewChildren('singleFormGroup') allFormGroups:
    | QueryList<ElementRef>
    | undefined;

  ngOnInit() {
    runInInjectionContext(this.injector, () => {
      effect(() => {
        this.childrenFormServerError()?.forEach((singleErrorObject, index) =>
          this.associateEachErrorObjectToTheCorrespondingFormGroup(
            singleErrorObject,
            this.childrenArrayOfFormGroups(),
            index
          )
        );
      });
    });
  }

  getOnlyErrorsRelatedToChildrenFormGroups(): semiComplexFormErrors[] {
    const errorsOnChildrenForms = (this.formErrorFromServer()['children'] ??
      []) as semiComplexFormErrors[];

    if (
      Array.isArray(errorsOnChildrenForms) &&
      errorsOnChildrenForms?.length > 0
    ) {
      // here we ignore the error object sent for the checked children
      errorsOnChildrenForms.splice(0, this.nbrOfCheckedChildren());
    }

    return errorsOnChildrenForms as semiComplexFormErrors[];
  }
}
