<div class="modal-body w-100">
  <div class="bg-body w-100">
    <div class="container w-100">
      <div class="row w-100">
        <div class="col-sm-auto w-100">
          <form [formGroup]="updateProfileForm">
            <!-- -->
            <!-- START FAMILY LINK FIELD -->
            <div class="form-group mb-3">
              <label
                class="form-label mlk-text-black mlk-input-required fw-bold"
                for="familyLink"
                >Lien avec la famille
                <span class="mlk-text-dark-grey fw-normal">- requis</span>
              </label>
              <div class="input-group">
                <span class="input-group-text"
                  ><em class="bi bi-link"></em
                ></span>
                <select
                  aria-label="familyLink"
                  class="form-select"
                  formControlName="family_link"
                  id="gender">
                  <option [selected]="true" value="">Choisir</option>
                  <option value="male">Conjoint</option>
                  <option value="female">Enfant</option>
                  <option value="female">Invité</option>
                </select>
              </div>
              @if (
                familyLink.invalid && (familyLink.dirty || familyLink.touched)
              ) {
                <div class="text-danger">
                  @if (familyLink.getError('required')) {
                    <span> Veuillez renseigner le lien avec la famille </span>
                  }
                </div>
              }
              @if (formErrorFromServer(); as formErrorFromServerValue) {
                @if (formErrorFromServerValue['family_link']) {
                  <div class="text-danger">
                    {{ formErrorFromServerValue['family_link'] }}
                  </div>
                }
              }
            </div>
            <!-- END FAMILY LINK FIELD-->
            <!-- START PRENOM FIELD -->
            <div class="form-group mb-3">
              <label
                class="form-label mlk-text-black mlk-input-required fw-bold"
                for="firstname"
                >Prénom
                <span class="mlk-text-dark-grey fw-normal">- requis</span>
              </label>
              <div class="input-group">
                <span class="input-group-text"
                  ><em class="bi bi-person"></em
                ></span>
                <input
                  aria-describedby="user-name-icon2"
                  aria-label="firstname"
                  class="form-control"
                  formControlName="first_name"
                  id="firstname"
                  type="text" />
              </div>
              @if (
                firstName.invalid && (firstName.dirty || firstName.touched)
              ) {
                <div class="text-danger">
                  @if (
                    firstName.getError('required') ||
                    firstName.getError('minLength')
                  ) {
                    <span> Veuillez renseigner votre prénom </span>
                  }
                  @if (firstName.getError('maxLength')) {
                    <span>
                      Votre prénom ne peut pas excéder 50 caractères
                    </span>
                  }
                </div>
              }
              @if (formErrorFromServer(); as formErrorFromServerValue) {
                @if (formErrorFromServerValue['first_name']) {
                  <div class="text-danger">
                    {{ formErrorFromServerValue['first_name'] }}
                  </div>
                }
              }
            </div>
            <!-- END PRENOM FIELD-->
            <!-- START NOM FIELD -->
            <div class="form-group mb-3">
              <label
                class="form-label mlk-text-black mlk-input-required fw-bold"
                for="lastname"
                >Nom
                <span class="mlk-text-dark-grey fw-normal">- requis</span>
              </label>
              <div class="input-group">
                <span class="input-group-text"
                  ><em class="bi bi-person"></em
                ></span>
                <input
                  aria-describedby="user-name-icon1"
                  aria-label="lastname"
                  class="form-control"
                  formControlName="last_name"
                  id="lastname"
                  type="text" />
              </div>
              @if (lastName.invalid && (lastName.dirty || lastName.touched)) {
                <div class="text-danger">
                  @if (
                    lastName.getError('required') ||
                    lastName.getError('minLength')
                  ) {
                    <span> Vous devez renseigner votre nom </span>
                  }
                  @if (lastName.getError('maxLength')) {
                    <span> Votre nom ne peut pas excéder 50 caractères </span>
                  }
                </div>
              }
              @if (formErrorFromServer(); as formErrorFromServerValue) {
                @if (formErrorFromServerValue['last_name']) {
                  <div class="text-danger">
                    {{ formErrorFromServerValue['last_name'] }}
                  </div>
                }
              }
            </div>
            <!-- END NOM FIELD -->
            <!-- START GENDER FIELD -->
            <div class="form-group mb-3">
              <label
                class="form-label mlk-text-black mlk-input-required fw-bold"
                for="gender"
                >Genre
                <span class="mlk-text-dark-grey fw-normal">- requis</span>
              </label>
              <div class="input-group">
                <span class="input-group-text"
                  ><em class="bi bi-person"></em
                ></span>
                <select
                  aria-label="gender"
                  class="form-select"
                  formControlName="gender"
                  id="gender">
                  <option [selected]="true" value="">Choisir</option>
                  <option value="male">Homme</option>
                  <option value="female">Femme</option>
                </select>
              </div>
              @if (gender.invalid && (gender.dirty || gender.touched)) {
                <div class="text-danger">
                  @if (gender.getError('required')) {
                    <span> Veuillez renseigner le genre </span>
                  }
                </div>
              }
              @if (formErrorFromServer(); as formErrorFromServerValue) {
                @if (formErrorFromServerValue['gender']) {
                  <div class="text-danger">
                    {{ formErrorFromServerValue['gender'] }}
                  </div>
                }
              }
            </div>
            <!-- END GENDER FIELD -->
            <!-- START EMAIL FIELD -->
            <div class="form-group mb-3">
              <label
                class="form-label mlk-text-black mlk-input-required fw-bold"
                for="email"
                >Email
                <span class="mlk-text-dark-grey fw-normal">- requis</span>
              </label>
              <div class="input-group">
                <span class="input-group-text text-bold"
                  ><em class="bi bi-envelope"></em
                ></span>
                <input
                  aria-describedby="email-icon"
                  aria-label="email"
                  class="form-control"
                  formControlName="email"
                  id="email"
                  type="email" />
              </div>
              @if (email.invalid && (email.dirty || email.touched)) {
                <div class="text-danger">
                  @if (
                    email.getError('required') || email.getError('minLength')
                  ) {
                    <span> Veuillez renseigner votre email </span>
                  }
                  @if (email.getError('maxLength')) {
                    <span> Votre email ne peut pas excéder 50 caractères </span>
                  }
                  @if (email.getError('email')) {
                    <span> Votre adresse mail est invalide </span>
                  }
                </div>
              }
              @if (formErrorFromServer(); as formErrorFromServerValue) {
                @if (formErrorFromServerValue['email']) {
                  <div class="text-danger">
                    Un compte existe déjà avec cette adresse email. Utilisez une
                    autre adresse email, ou connectez-vous sur la page “Se
                    connecter” ou en cliquant sur le lien ‘Connectez-vous” au
                    bas du formulaire.
                  </div>
                }
              }
            </div>
            <!-- END EMAIL FIELD -->
            <!-- START TELEPHONE FIELD -->
            <div class="form-group mb-3">
              <label
                class="form-label mlk-text-black mlk-input-required fw-bold"
                for="telephone"
                >Téléphone
                <span class="mlk-text-dark-grey fw-normal">- requis</span>
              </label>
              <div class="input-group">
                <!--            <input type="telephone" id="telephone" formControlName="phone_number" class="form-control"-->
                <!--                   aria-label="telephone" aria-describedby="email-icon">-->
                <ngx-intl-tel-input
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="false"
                  [maxLength]="15"
                  [phoneValidation]="true"
                  [preferredCountries]="[countryISOFr]"
                  [searchCountryField]="searchCountryField"
                  [searchCountryFlag]="true"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="countryISOFr"
                  [separateDialCode]="true"
                  formControlName="phone_number"
                  id="telephone"></ngx-intl-tel-input>
              </div>
              @if (phoneNumber.invalid && phoneNumber.touched) {
                <div class="text-danger">
                  Le numéro de téléphone est requis.
                </div>
              }
              @if (formErrorFromServer(); as formErrorFromServerValue) {
                @if (formErrorFromServerValue['phone_number']) {
                  <div class="text-danger">
                    {{ formErrorFromServerValue['phone_number'] }} Use the E.164
                    international format
                  </div>
                }
              }
            </div>
            <!-- END TELEPHONE FIELD -->
            <div class="form-group text-end mb-4">
              <button
                (click)="onSubmit()"
                [disabled]="updateProfileForm.invalid"
                class="btn mlk-btn-blue-filled"
                type="submit">
                Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
