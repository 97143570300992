@if (embassy$ | async; as embassy) {
  <div class="d-flex gap-0 flex-column">
    <h5 class="h5 fw-bold">
      {{ embassy.name }}
      {{ embassy.postal_code }} {{ embassy.city }}
    </h5>
    @if (canShowAddressMessage) {
      <span
        >Vous recevrez l’adresse exacte par SMS.</span
        >
      }
    </div>
  }
  