<div [formGroup]="relatedFormGroup()">
  <div [formArrayName]="relatedFormArrayName()">
    @for (
      child of childrenArrayOfFormGroups();
      track child;
      let index = $index
    ) {
      <div class="mlk-card mb-3" #singleFormGroup>
        <h5 class="h5 fw-bold mb-3">Enfant {{ index + 1 }}</h5>
        <div [formGroupName]="index">
          <!-- START PRENOM FIELD -->
          <div class="form-group mb-3">
            <label
              class="form-label mlk-text-black mlk-input-required fw-bold"
              for="firstname"
              >Prénom
              <span class="mlk-text-dark-grey fw-normal">- requis</span>
            </label>
            <div class="input-group">
              <span class="input-group-text"
                ><em class="bi bi-person"></em
              ></span>
              <input
                aria-describedby="user-name-icon2"
                aria-label="firstname"
                class="form-control"
                formControlName="first_name"
                id="firstname"
                type="text" />
            </div>
            @if (
              firstNameOf(child)?.invalid &&
              (firstNameOf(child)?.dirty || firstNameOf(child)?.touched)
            ) {
              <div class="text-danger">
                @if (
                  firstNameOf(child)?.hasError('required') ||
                  firstNameOf(child)?.hasError('minLength')
                ) {
                  <span> Veuillez renseigner le prénom </span>
                }
                @if (firstNameOf(child)?.hasError('maxLength')) {
                  <span> Le prénom ne peut pas excéder 50 caractères </span>
                }
              </div>
            }
            @if (firstNameOf(child)?.hasError('server')) {
              <div class="text-danger">
                {{ firstNameOf(child)?.errors?.['server'] }}
              </div>
            }
          </div>
          <!-- END PRENOM FIELD-->
          <!-- START NOM FIELD -->
          <div class="form-group mb-3">
            <label
              class="form-label mlk-text-black mlk-input-required fw-bold"
              for="lastname"
              >Nom
              <span class="mlk-text-dark-grey fw-normal">- requis</span>
            </label>
            <div class="input-group">
              <span class="input-group-text"
                ><em class="bi bi-person"></em
              ></span>
              <input
                aria-describedby="user-name-icon1"
                aria-label="lastname"
                class="form-control"
                formControlName="last_name"
                id="lastname"
                type="text" />
            </div>
            @if (lastNameOf(child)?.hasError('server')) {
              <div class="text-danger">
                {{ lastNameOf(child)?.errors?.['server'] }}
              </div>
            }
            @if (
              lastNameOf(child)?.invalid &&
              (lastNameOf(child)?.dirty || lastNameOf(child)?.touched)
            ) {
              <div class="text-danger">
                @if (
                  lastNameOf(child)?.hasError('required') ||
                  lastNameOf(child)?.hasError('minLength')
                ) {
                  <span> Vous devez renseigner le nom </span>
                }
                @if (lastNameOf(child)?.hasError('maxLength')) {
                  <span> Le nom ne peut pas excéder 50 caractères </span>
                }
              </div>
            }
          </div>
          <!-- END NOM FIELD -->

          <!-- START DATE -->
          <div class="form-group mb-3">
            <label class="form-label fw-bold" for="date"
              >Date de naissance ( cliquer sur l'icône
              <i class="bi bi-calendar3"></i> à droite )
              <span class="mlk-text-dark-grey fw-normal">- requis</span>
            </label>
            <div class="input-group">
              <span class="input-group-text"
                ><i class="bi bi-calendar-week"></i
              ></span>
              <input
                #d="ngbDatepicker"
                class="form-control"
                formControlName="date_of_birth"
                id="date"
                ngbDatepicker
                placeholder="yyyy-mm-dd" />
              <button
                (click)="d.toggle()"
                class="btn btn-outline-secondary bi bi-calendar3"
                type="button"></button>
            </div>
            @if (
              dateOfBirthOf(child)?.invalid &&
              (dateOfBirthOf(child)?.dirty || dateOfBirthOf(child)?.touched)
            ) {
              <div class="text-danger">
                @if (dateOfBirthOf(child)?.hasError('required')) {
                  <small> La date de naissance est requise </small>
                }
              </div>

              @if (dateOfBirthOf(child)?.hasError('invalidDate')) {
                <div class="text-danger">
                  {{ dateOfBirthOf(child)?.errors?.['invalidDate'] }}
                </div>
              }
            }
            @if (dateOfBirthOf(child)?.hasError('server')) {
              <div class="text-danger">
                {{ dateOfBirthOf(child)?.errors?.['server'] }}
              </div>
            }
          </div>
          <!--END DATE -->

          <!-- START GENDER FIELD -->
          <div class="form-group mb-3">
            <label
              class="form-label mlk-text-black mlk-input-required fw-bold"
              for="gender"
              >Genre
              <span class="mlk-text-dark-grey fw-normal">- requis</span>
            </label>
            <div class="input-group">
              <span class="input-group-text"
                ><em class="bi bi-person"></em
              ></span>
              <select
                aria-label="gender"
                class="form-select"
                formControlName="gender"
                id="gender">
                <option [selected]="true" value="">Choisir</option>
                <option value="male">Homme</option>
                <option value="female">Femme</option>
              </select>
            </div>
            @if (
              genderOf(child)?.invalid &&
              (genderOf(child)?.dirty || genderOf(child)?.touched)
            ) {
              <div class="text-danger">
                @if (genderOf(child)?.hasError('required')) {
                  <span> Veuillez renseigner le genre </span>
                }
              </div>
            }
            @if (genderOf(child)?.hasError('server')) {
              <div class="text-danger">
                {{ genderOf(child)?.errors?.['server'] }}
              </div>
            }
          </div>
          <!-- END GENDER FIELD -->

          <!--START SHOULD REMEMBER FIELD-->
          <div class="form-group mb-3">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                formControlName="is_reusable"
                id="is_reusable"
                name="is_reusable"
                type="checkbox" />
              <label class="form-check-label" for="is_reusable"
                >Enregistrer pour la prochaine fois
              </label>
            </div>
          </div>
          <!--END SHOULD REMEMBER FIELD-->

          <mlk-button
            additionalClasses="mt-3 shadow"
            variant="filled"
            type="primary"
            ngbTooltip="Retirer"
            placement="right"
            (click)="removeChildEvent.emit(index)">
            <i class="bi bi-trash3"></i>
          </mlk-button>
        </div>
      </div>
    }
  </div>
</div>
