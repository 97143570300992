import {
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  Injector,
  input,
  OnInit,
  output,
  QueryList,
  runInInjectionContext,
  Signal,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  CountryISO,
  NgxIntlTelInputModule,
  SearchCountryField,
} from 'ngx-intl-tel-input';
import { complexFormErrors, semiComplexFormErrors } from '@common/rxjs';
import { MlkButtonComponent } from '@shared/ui';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { BaseFormClass } from '@shared/components/form/base-form-class';

@Component({
  selector: 'app-guest-attendee-form',
  standalone: true,
  imports: [
    FormsModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    MlkButtonComponent,
    NgbTooltip,
  ],
  templateUrl: './guest-attendee-form.component.html',
  styleUrl: './guest-attendee-form.component.scss',
})
export class GuestAttendeeFormComponent
  extends BaseFormClass
  implements OnInit
{
  countryISO = input.required<CountryISO>();
  searchCountryField = input.required<SearchCountryField[]>();
  formErrorFromServer = input.required<complexFormErrors>({});
  guestFormServerError: Signal<semiComplexFormErrors[]> = computed(() =>
    this.getOnlyErrorsRelatedToGuestFormGroups()
  );
  guestsArrayOfFormGroups = input.required<AbstractControl[]>();
  relatedFormArrayName = input.required<string>();
  relatedFormGroup = input.required<FormGroup>();
  removeGuestEvent = output<number>();
  injector = inject(Injector);

  @ViewChildren('singleFormGroup') allFormGroups:
    | QueryList<ElementRef>
    | undefined;

  ngOnInit() {
    runInInjectionContext(this.injector, () => {
      effect(() => {
        this.guestFormServerError()?.forEach((singleErrorObject, index) =>
          this.associateEachErrorObjectToTheCorrespondingFormGroup(
            singleErrorObject,
            this.guestsArrayOfFormGroups(),
            index
          )
        );
      });
    });
  }

  getOnlyErrorsRelatedToGuestFormGroups(): semiComplexFormErrors[] {
    return (this.formErrorFromServer()['guests'] ??
      []) as semiComplexFormErrors[];
  }
}
