import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function confirmEqualValidator(
  main: string,
  confirm: string
): ValidatorFn {
  return (ctrl: AbstractControl): null | ValidationErrors => {
    const password = ctrl.get(main);
    const rPassword = ctrl.get(confirm);

    if (!password || !rPassword) {
      throw new Error('control not exist');
    }

    if (password.value === rPassword.value) {
      return null;
    }

    return {
      notEqual: true,
    };
  };
}
