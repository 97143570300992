import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

export const checkHours = (
  fromControlName: string,
  toControlName: string
): ValidatorFn => {
  return (ctrl: AbstractControl): null | ValidationErrors => {
    const from = ctrl.get(fromControlName);
    const to = ctrl.get(toControlName);

    if (!from || !to) {
      throw new Error('control not exist');
    }

    const fromValue = from.value as NgbTimeStruct | null,
      toValue = to.value as NgbTimeStruct | null;

    if (!fromValue || !toValue) {
      return null;
    }

    if (fromValue.hour >= toValue.hour) {
      return {
        superiorThanAutorize: true,
      };
    }
    return null;
  };
};
