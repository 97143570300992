import {
  assertInInjectionContext,
  inject,
  WritableSignal,
} from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { ToastService } from '@shared/services';

export type arrayOfString = string[];
export type basicFormErrors = { [key: string]: string[] };
export type semiComplexFormErrors = { [key: string]: arrayOfString };
export type complexFormErrors = {
  [key: string]: basicFormErrors | semiComplexFormErrors[];
};

export type FormErrors = { [key: string]: string };

export const buildFormErrors = (serverErrors: FormErrors): FormErrors => {
  return Object.keys(serverErrors).reduce((acc, curr) => {
    return { ...acc, [curr]: serverErrors[curr][0] };
  }, {} satisfies FormErrors);
};

export function catchFormError<T, U>(
  signal: WritableSignal<FormErrors | null>,
  callback?: (value: any) => U
): (source$: Observable<T>) => Observable<T | U> {
  assertInInjectionContext(catchFormError);
  const toastService = inject(ToastService);
  return source$ =>
    source$.pipe(
      catchError(e => {
        if (e.error instanceof ErrorEvent) {
          toastService.showDanger(
            'Une erreur est survenue lors de la création de votre compte. Veuillez réessayer plus tard'
          );
        }

        if (e.status === 400) {
          const serverErrors = buildFormErrors(e.error);
          signal.set(serverErrors);
        }

        if (callback) {
          return of(callback(e));
        }

        return of(e);
      })
    );
}
