<div class="mt-0">
  <div class="d-flex flex-column gap-3 w-100">
    <form [formGroup]="eventAttendeesForm">
      <app-child-attendee-form
        (removeChildEvent)="removeChild($event)"
        [childrenArrayOfFormGroups]="children.controls"
        [formErrorFromServer]="formErrorFromServer()"
        [nbrOfCheckedChildren]="nbrOfCheckedChildren()"
        [relatedFormGroup]="eventAttendeesForm"
        id="child-form"
        relatedFormArrayName="children" />

      <app-guest-attendee-form
        (removeGuestEvent)="removeGuest($event)"
        [countryISO]="countryISO"
        [formErrorFromServer]="formErrorFromServer()"
        [guestsArrayOfFormGroups]="guests.controls"
        [relatedFormGroup]="eventAttendeesForm"
        [searchCountryField]="searchCountryField"
        id="guest-form"
        relatedFormArrayName="guests" />

      <app-partner-attendee-form
        (removePartnerEvent)="removePartner($event)"
        [countryISO]="countryISO"
        [formErrorFromServer]="formErrorFromServer()"
        [partnerArrayOfFormGroups]="partner.controls"
        [relatedFormGroup]="eventAttendeesForm"
        [searchCountryField]="searchCountryField"
        id="partner-form"
        relatedFormArrayName="partner" />

      <div class="w-100 py-4 d-flex justify-content-between">
        <div class="d-flex flex-column flex-lg-row w-100 gap-3">
          <mlk-button
            (click)="onSubmit()"
            [disabled]="false"
            additionalClasses="w-100 px-5"
            type="primary"
            variant="filled">
            Valider
          </mlk-button>

          @if (currentNumberOfAttendees() < 9) {
            <mlk-button
              variant="outlined"
              type="primary"
              (click)="addChild()"
              additionalClasses="w-100">
              <i class="bi bi-person-fill-add h5"></i> Enfants
            </mlk-button>
            <mlk-button
              additionalClasses="w-100"
              variant="outlined"
              type="primary"
              (click)="addGuest()">
              <i class="bi bi-person-fill-add h5"></i> Invités
            </mlk-button>

            @if (canAddPartnerThroughForm()) {
              <mlk-button
                additionalClasses="w-100"
                variant="outlined"
                type="primary"
                (click)="addPartner()">
                <i class="bi bi-person-fill-add h5"></i> Conjoint(e)
              </mlk-button>
            }
          }
        </div>
      </div>
    </form>
  </div>
</div>
