<button
  type="button"
  (click)="onRefuseAttend()"
  [disabled]="(status() && status() !== 'pending') || actionInprogress()"
  class="btn mlk-btn-blue-outline">
  Refuser
</button>
<button
  type="button"
  (click)="onAcceptAttend()"
  [disabled]="(status() && status() !== 'pending') || actionInprogress()"
  class="btn mlk-btn-blue-filled">
  Accepter
</button>
