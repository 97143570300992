<div [formGroup]="relatedFormGroup()">
  <div [formArrayName]="relatedFormArrayName()">
    @for (guest of guestsArrayOfFormGroups(); track guest; let index = $index) {
      <div class="mlk-card mb-3" #singleFormGroup>
        <h5 class="h5 fw-bold mb-3">Invité {{ index + 1 }}</h5>
        <div [formGroupName]="index">
          <!-- START PRENOM FIELD -->
          <div class="form-group mb-3">
            <label
              class="form-label mlk-text-black mlk-input-required fw-bold"
              for="firstname"
              >Prénom
              <span class="mlk-text-dark-grey fw-normal">- requis</span>
            </label>
            <div class="input-group">
              <span class="input-group-text"
                ><em class="bi bi-person"></em
              ></span>
              <input
                aria-describedby="user-name-icon2"
                aria-label="firstname"
                class="form-control"
                formControlName="first_name"
                id="firstname"
                type="text" />
            </div>
            @if (
              firstNameOf(guest)?.invalid &&
              (firstNameOf(guest)?.dirty || firstNameOf(guest)?.touched)
            ) {
              <div class="text-danger">
                @if (
                  firstNameOf(guest)?.hasError('required') ||
                  firstNameOf(guest)?.hasError('minLength')
                ) {
                  <span> Veuillez renseigner le prénom </span>
                }
                @if (firstNameOf(guest)?.hasError('maxLength')) {
                  <span> Le prénom ne peut pas excéder 50 caractères </span>
                }
              </div>
            }
            @if (firstNameOf(guest)?.hasError('server')) {
              <div class="text-danger">
                {{ firstNameOf(guest)?.errors?.['server'] }}
              </div>
            }
          </div>
          <!-- END PRENOM FIELD-->

          <!-- START NOM FIELD -->
          <div class="form-group mb-3">
            <label
              class="form-label mlk-text-black mlk-input-required fw-bold"
              for="lastname"
              >Nom
              <span class="mlk-text-dark-grey fw-normal">- requis</span>
            </label>
            <div class="input-group">
              <span class="input-group-text"
                ><em class="bi bi-person"></em
              ></span>
              <input
                aria-describedby="user-name-icon1"
                aria-label="lastname"
                class="form-control"
                formControlName="last_name"
                id="lastname"
                type="text" />
            </div>
            @if (lastNameOf(guest)?.hasError('server')) {
              <div class="text-danger">
                {{ lastNameOf(guest)?.errors?.['server'] }}
              </div>
            }
            @if (
              lastNameOf(guest)?.invalid &&
              (lastNameOf(guest)?.dirty || lastNameOf(guest)?.touched)
            ) {
              <div class="text-danger">
                @if (
                  lastNameOf(guest)?.hasError('required') ||
                  lastNameOf(guest)?.hasError('minLength')
                ) {
                  <span> Vous devez renseigner le nom </span>
                }
                @if (lastNameOf(guest)?.hasError('maxLength')) {
                  <span> Le nom ne peut pas excéder 50 caractères </span>
                }
              </div>
            }
          </div>
          <!-- END NOM FIELD -->

          <!-- START GENDER FIELD -->
          <div class="form-group mb-3">
            <label
              class="form-label mlk-text-black mlk-input-required fw-bold"
              for="gender"
              >Genre
              <span class="mlk-text-dark-grey fw-normal">- requis</span>
            </label>
            <div class="input-group">
              <span class="input-group-text"
                ><em class="bi bi-person"></em
              ></span>
              <select
                aria-label="gender"
                class="form-select"
                formControlName="gender"
                id="gender">
                <option [selected]="true" value="">Choisir</option>
                <option value="male">Homme</option>
                <option value="female">Femme</option>
              </select>
            </div>
            @if (
              genderOf(guest)?.invalid &&
              (genderOf(guest)?.dirty || genderOf(guest)?.touched)
            ) {
              <div class="text-danger">
                @if (genderOf(guest)?.hasError('required')) {
                  <span> Veuillez renseigner le genre </span>
                }
              </div>
            }
            @if (genderOf(guest)?.hasError('server')) {
              <div class="text-danger">
                {{ genderOf(guest)?.errors?.['server'] }}
              </div>
            }
          </div>
          <!-- END GENDER FIELD -->

          <!-- START EMAIL FIELD -->
          <div class="form-group mb-3">
            <label
              class="form-label mlk-text-black mlk-input-required fw-bold"
              for="email"
              >Email
              <span class="mlk-text-dark-grey fw-normal">- requis</span>
            </label>
            <div class="input-group">
              <span class="input-group-text text-bold"
                ><em class="bi bi-envelope"></em
              ></span>
              <input
                aria-describedby="email-icon"
                aria-label="email"
                class="form-control"
                formControlName="email"
                id="email"
                type="email" />
            </div>
            @if (
              emailOf(guest)?.invalid &&
              (emailOf(guest)?.dirty || emailOf(guest)?.touched)
            ) {
              <div class="text-danger">
                @if (
                  emailOf(guest)?.hasError('required') ||
                  emailOf(guest)?.hasError('minLength')
                ) {
                  <span> Veuillez renseigner l'email </span>
                }
                @if (emailOf(guest)?.hasError('maxLength')) {
                  <span> l'email ne peut pas excéder 50 caractères </span>
                }
                @if (emailOf(guest)?.hasError('email')) {
                  <span> l'adresse mail est invalide </span>
                }
              </div>
            }
            @if (emailOf(guest)?.hasError('server')) {
              <div class="text-danger">
                {{ emailOf(guest)?.errors?.['server'] }}
              </div>
            }
          </div>
          <!-- END EMAIL FIELD -->

          <!-- START TELEPHONE FIELD -->
          <div class="form-group mb-3">
            <label
              class="form-label mlk-text-black mlk-input-required fw-bold"
              for="telephone"
              >Téléphone
              <span class="mlk-text-dark-grey fw-normal">- requis</span>
            </label>
            <div class="input-group">
              <!--            <input type="telephone" id="telephone" formControlName="phone_number" class="form-control"-->
              <!--                   aria-label="telephone" aria-describedby="email-icon">-->
              <ngx-intl-tel-input
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="false"
                [maxLength]="15"
                [phoneValidation]="true"
                [preferredCountries]="[countryISO()]"
                [searchCountryField]="searchCountryField()"
                [searchCountryFlag]="true"
                [selectFirstCountry]="false"
                [selectedCountryISO]="countryISO()"
                [separateDialCode]="true"
                formControlName="phone_number"
                id="telephone"></ngx-intl-tel-input>
            </div>
            @if (
              phoneNumberOf(guest)?.invalid && phoneNumberOf(guest)?.touched
            ) {
              <div class="text-danger">Le numéro de téléphone est requis.</div>
            }
            @if (phoneNumberOf(guest)?.hasError('server')) {
              <div class="text-danger">
                {{ phoneNumberOf(guest)?.errors?.['server'] }}
              </div>
            }
          </div>
          <!-- END THELEPHONE FIELD -->

          <!-- START RANGE FIELD -->
          <div class="form-group mb-3">
            <label
              class="form-label mlk-text-black mlk-input-required fw-bold"
              for="age_range"
              >Tranche d'âge
              <span class="mlk-text-dark-grey fw-normal">- requis</span>
            </label>
            <div class="input-group">
              <span class="input-group-text"
                ><em class="bi bi-person"></em
              ></span>
              <select
                aria-label="age_range"
                class="form-select"
                formControlName="age_range"
                id="age_range">
                <option [selected]="true" value="">Choisir</option>
                <option value="0-17">0-17</option>
                <option value="18-35">18-35</option>
                <option value="36-59">36-59</option>
                <option value="60+">60+</option>
              </select>
            </div>
            @if (
              ageRangeOf(guest)?.invalid &&
              (ageRangeOf(guest)?.dirty || ageRangeOf(guest)?.touched)
            ) {
              <div class="text-danger">
                @if (ageRangeOf(guest)?.hasError('required')) {
                  <span> Veuillez renseigner la tranche d'âge </span>
                }
              </div>
            }
            @if (ageRangeOf(guest)?.hasError('server')) {
              <div class="text-danger">
                {{ ageRangeOf(guest)?.errors?.['server'] }}
              </div>
            }
          </div>

          <mlk-button
            additionalClasses="mt-3 shadow"
            variant="filled"
            type="primary"
            ngbTooltip="Retirer"
            placement="right"
            (click)="removeGuestEvent.emit(index)">
            <i class="bi bi-trash3"></i>
          </mlk-button>
        </div>
      </div>
    }
  </div>
</div>
