@for (toast of toastService.toasts; track toast) {
  <ngb-toast
    [class]="toast.className"
    [autohide]="true"
    [delay]="toast.delay || 5000"
    (hidden)="toastService.remove(toast)"
    [header]="toast.header">
    @if (isTemplate(toast)) {
      <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    } @else {
      {{ toast.textOrTpl }}
    }
  </ngb-toast>
}
