import { AbstractControl } from '@angular/forms';
import { basicFormErrors } from '@common/rxjs';
import { isObjectEmpty } from '@shared/helpers';

export class BaseFormClass {
  associateEachErrorObjectToTheCorrespondingFormGroup(
    singleErrorObject: basicFormErrors,
    arrayOfFormGroups: AbstractControl[],
    index: number
  ) {
    Object.keys(singleErrorObject).forEach(formControlName => {
      const correspondingFormGroup = arrayOfFormGroups[index];
      this.setErrorOnTheCorrespondingFormControl(
        singleErrorObject,
        correspondingFormGroup,
        formControlName
      );
    });
  }

  setErrorOnTheCorrespondingFormControl(
    singleErrorObject: basicFormErrors,
    formGroup: AbstractControl,
    formControlName: string
  ) {
    formGroup?.get(formControlName)?.setErrors({
      server: isObjectEmpty(singleErrorObject)
        ? false
        : singleErrorObject[formControlName]?.[0],
    });
  }

  protected firstNameOf(
    singleFormGroup: AbstractControl
  ): AbstractControl | null {
    return singleFormGroup?.get('first_name');
  }

  protected lastNameOf(
    singleFormGroup: AbstractControl
  ): AbstractControl | null {
    return singleFormGroup?.get('last_name');
  }

  protected dateOfBirthOf(
    singleFormGroup: AbstractControl
  ): AbstractControl | null {
    return singleFormGroup?.get('date_of_birth');
  }

  protected genderOf(singleFormGroup: AbstractControl): AbstractControl | null {
    return singleFormGroup?.get('gender');
  }

  protected emailOf(singleFormGroup: AbstractControl): AbstractControl | null {
    return singleFormGroup?.get('email');
  }

  protected phoneNumberOf(
    singleFormGroup: AbstractControl
  ): AbstractControl | null {
    return singleFormGroup?.get('phone_number');
  }

  protected ageRangeOf(
    singleFormGroup: AbstractControl
  ): AbstractControl | null {
    return singleFormGroup?.get('age_range');
  }
}
