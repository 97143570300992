import { Component, inject, input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'mlk-rating-view-button',
  standalone: true,
  template: `
    <button
      type="button"
      class="btn mlk-btn-blue-filled"
      (click)="onRatingView()">
      Laisser un avis
    </button>
  `,
})
export class RatingViewButtonComponent {
  private readonly router = inject(Router);

  readonly eventId = input.required<string>();

  readonly embassyId = input.required<string>();

  onRatingView(): void {
    this.router.navigateByUrl(
      `mlk/event/rating/${this.eventId()}/${this.embassyId()}`
    );
  }
}
