<div class="mlk-login-register-card p-4 mt-5 mt-lg-0 shadow rounded-4 border-0">
  <h2 class="mlk-text-black fw-bold">Nouveau mot de passe</h2>
  <form [formGroup]="changePasswordForm" class="mt-4">
    <div class="form-group mb-3 position-relative">
      <ng-template #htmlContent>
        <ul>
          <li>10 caractères minimum</li>
          <li>1 lettre minuscule</li>
          <li>1 lettre majuscule</li>
          <li>1 chiffre</li>
          <li>1 caractère spécial (!&#64;#$%^&*)</li>
        </ul>
      </ng-template>
      <label
        for="password"
        class="form-label mlk-text-green mlk-input-required fw-bold"
        >Mot de passe
        <span class="mlk-text-dark-grey fw-normal"
          >- requis
          <i
            class="bi bi-info-circle"
            placement="top"
            [ngbTooltip]="htmlContent"></i
        ></span>
      </label>
      <span class="hide-show-password-eye" (click)="passwordStateChange()">
        <i
          [ngClass]="showPassword() ? 'bi-eye-slash' : 'bi-eye'"
          class="bi"></i>
      </span>
      <div class="input-group">
        <span class="input-group-text"
          ><em class="bi bi-lock-fill has-validation"></em
        ></span>
        <input
          [type]="showPassword() ? 'text' : 'password'"
          id="password"
          formControlName="password"
          [ngClass]="{ 'is-invalid': (showPasswordError$ | async) }"
          class="form-control"
          aria-label="password"
          aria-describedby="basic-addon1" />
      </div>
      @if (showPasswordError$ | async) {
        <span class="d-inline-block mb-2 text-danger"
          >Les mots de passe ne correspondent pas</span
        >
      }
    </div>
    <div class="form-group mb-4 position-relative">
      <label
        for="password-confirm"
        class="form-label mlk-text-green mlk-input-required fw-bold">
        Confirmation de mot de passe
        <span class="mlk-text-dark-grey fw-normal">- requis</span>
      </label>
      <span class="hide-show-password-eye" (click)="passwordStateChange()">
        <i
          [ngClass]="showPassword() ? 'bi-eye-slash' : 'bi-eye'"
          class="bi"></i>
      </span>
      <div class="input-group">
        <span class="input-group-text"><em class="bi bi-lock-fill"></em></span>
        <input
          [type]="showPassword() ? 'text' : 'password'"
          id="password-confirm"
          [ngClass]="{ 'is-invalid': (showPasswordError$ | async) }"
          formControlName="confirmPassword"
          class="form-control"
          aria-label="Username"
          aria-describedby="basic-addon1" />
      </div>
    </div>
    <div class="form-group text-center mb-4">
      <button
        type="submit"
        [disabled]="changePasswordForm.invalid"
        (click)="onSubmitForm()"
        class="btn w-100 mlk-btn-blue-filled">
        Enregistrer
      </button>
    </div>
    <div class="form-group text-center">
      <p>
        Pas de compte ?
        <a
          class="mlk-link fw-bold mlk-text-dark-blue"
          [routerLink]="['/register']"
          >Inscrivez-vous gratuitement</a
        >
      </p>
    </div>
  </form>
</div>
